
function SampleText() {
  // Language names: https://www.internationalphoneticalphabet.org/languages/language-names-in-native-language/
  // English: unknown source
  // General source: https://translateastory.org/, 
  // Multiple translations: https://storyweaver.org.in/
  // Korean: https://freshkorean.com/tag/korean-fairy-tale/
  // Spanish: https://www.thespanishexperiment.com/stories/threepigs
  // Chinese: https://goeastmandarin.com/chinese-short-stories-pinyin-pdf/
  // Hindi: https://yourstoryclub.com/story-category/hindi/index.html
  this.allLanguages = {
    english: {
      description: 'English',
      text: [
        'Once upon a time there were four little Rabbits, and their names were Flopsy, Mopsy, Cotton-tail, and Peter.'
        , 'They lived with their Mother in a sand-bank, underneath the root of a very big fir-tree.'
        , '"Now my dears," said old Mrs. Rabbit one morning, "you may go into the fields or down the lane, but don\'t go into Mr. McGregor\'s garden: your Father had an accident there; he was put in a pie by Mrs. McGregor."'
        , '"Now run along, and don\'t get into mischief. I am going out."'
        , 'Then old Mrs. Rabbit took a basket and her umbrella, and went through the wood to the baker\'s. She bought a loaf of brown bread and five currant buns.'
        , 'Flopsy, Mopsy, and Cotton-tail, who were good little bunnies, went down the lane to gather blackberries:'
        , 'But Peter, who was very naughty, ran straight away to Mr. McGregor\'s garden, and squeezed under the gate!'
        , 'First he ate some lettuces and some French beans; and then he ate some radishes;'
        , 'And then, feeling rather sick, he went to look for some parsley.'
        , 'But round the end of a cucumber frame, whom should he meet but Mr. McGregor!'
        , 'Mr. McGregor was on his hands and knees planting out young cabbages, but he jumped up and ran after Peter, waving a rake and calling out, "Stop thief!"'
        , 'Peter was most dreadfully frightened; he rushed all over the garden, for he had forgotten the way back to the gate.'
        , 'He lost one of his shoes among the cabbages, and the other shoe amongst the potatoes.'
        , 'After losing them, he ran on four legs and went faster, so that I think he might have got away altogether if he had not unfortunately run into a gooseberry net, and got caught by the large buttons on his jacket. It was a blue jacket with brass buttons, quite new.'
        , 'Peter gave himself up for lost, and shed big tears; but his sobs were overheard by some friendly sparrows, who flew to him in great excitement, and implored him to exert himself.'
        , 'Mr. McGregor came up with a sieve, which he intended to pop upon the top of Peter; but Peter wriggled out just in time, leaving his jacket behind him.'
        , 'And rushed into the tool-shed, and jumped into a can. It would have been a beautiful thing to hide in, if it had not had so much water in it.'
        , 'Mr. McGregor was quite sure that Peter was somewhere in the tool-shed, perhaps hidden underneath a flower-pot. He began to turn them over carefully, looking under each.'
        , 'Presently Peter sneezed, "Kertyschoo!" Mr. McGregor was after him in no time.'
        , 'And tried to put his foot upon Peter, who jumped out of a window, upsetting three plants. The window was too small for Mr. McGregor, and he was tired of running after Peter. He went back to his work.'
        , 'Peter sat down to rest; he was out of breath and trembling with fright, and he had not the least idea which way to go. Also he was very damp with sitting in that can.'
        , 'After a time he began to wander about, going lippity, lippity, not very fast, and looking all round.'
        , 'He found a door in a wall; but it was locked, and there was no room for a fat little rabbit to squeeze underneath.'
        , 'An old mouse was running in and out over the stone doorstep, carrying peas and beans to her family in the wood. Peter asked her the way to the gate, but she had such a large pea in her mouth that she could not answer. She only shook her head at him. Peter began to cry.'
        , 'Then he tried to find his way straight across the garden, but he became more and more puzzled. Presently, he came to a pond where Mr. McGregor filled his water-cans. A white cat was staring at some gold-fish, she sat very, very still, but now and then the tip of her tail twitched as if it were alive. Peter thought it best to go away without speaking to her; he had heard about cats from his cousin, little Benjamin Bunny.'
        , 'He went back towards the tool-shed, but suddenly, quite close to him, he heard the noise of a hoe? Scr-r-ritch, scratch, scratch, scritch. Peter scuttered underneath the bushes. But presently, as nothing happened, he came out, and climbed upon a wheelbarrow and peeped over. The first thing he saw was Mr. McGregor hoeing onions. His back was turned towards Peter, and beyond him was the gate!'
        , 'Peter got down very quietly off the wheelbarrow; and started running as fast as he could go, along a straight walk behind some black-currant bushes.'
        , 'Mr. McGregor caught sight of him at the corner, but Peter did not care. He slipped underneath the gate, and was safe at last in the wood outside the garden.'
        , 'Mr. McGregor hung up the little jacket and the shoes for a scare-crow to frighten the blackbirds.'
        , 'Peter never stopped running or looked behind him till he got home to the big fir-tree.'
        , 'He was so tired that he flopped down upon the nice soft sand on the floor of the rabbit-hole and shut his eyes. His mother was busy cooking; she wondered what he had done with his clothes. It was the second little jacket and pair of shoes that Peter had lost in a fortnight!'
        , 'I am sorry to say that Peter was not very well during the evening.'
        , 'His mother put him to bed, and made some camomile tea; and she gave a dose of it to Peter!'
        , '"One table-spoonful to be taken at bed-time."'
        , 'But Flopsy, Mopsy, and Cotton-tail had bread and milk and blackberries for supper.'
      ]
    },
    korean: {
      description: 'Korean (한국어)',
      text: [
        '옛날 아주 먼 옛날에, 토끼와 거북이가 살고 있었습니다.'
        , '토끼와 거북이가 사는 아름다운 이 마을에 원숭이,개, 고양이, 사슴,너구리,새 등등 여러 동물들도 함께 살고 있었습니다.'
        , '어느날, 민첩하고 재빠른 토끼와 느린 거북이가 이야기를 하고 있었습니다.'
        , '토끼: 흠… 저게 누구야? 느림보 거북이잖아? 인사나 해볼까…? 거북이: 토끼야, 안녕? 잘 지냈어?'
        , '토끼: 니가 무슨 상관이야? 이 느림보 거북이 주제에! 흥!! 거북이: 뭐? 내가 느림보 거북이라고?'
        , '토끼: 느림보래요~ 거북이는~ 느림보래요~ 하하하하하 거북이: 좋아, 내가 느림보 거북이가 아니라는 걸 보여주겠어. 토끼 너! 나랑'
        , '달리기 경주 하지 않을래?'
        , '토끼: 너랑 달리기 경주? 하하하하하 좋아. 어디 누가 이기는지 두고보자 거북아!'
        , '그렇게 토끼와 거북이는 달리기 경주를 하기로 약속한 뒤 , 다음날 경주를'
        , '하기로 한 장소에 모였습니다.'
        , '그 뒤에는 다른 동물 친구들도 토끼와 거북이의 경주를 구경하기 위해 서있었습니다.'
        , '토끼와 거북이는 출발선 앞에 섰습니다. 원숭이가 심판이 되어 토끼와 거북이의 경주를 봐주기로 했습니다.'
        , '원숭이: 토끼, 거북아! 너희 모두 준비 되었니? 토끼: 응, 준비되었어. 거북이 너는 준비 되었니?'
        , '거북이: 물론이지! 원숭이: 좋아. 그럼 준비! 출발!'
        , '경주를 시작한 뒤 얼마되지 않아 토끼는 저만큼 앞서서 달리고 있고, 거북이는 땀을 뻘뻘 흘리며 열심히 기어가고 있습니다.'
        , '저 멀리서 동물 친구들이 토끼와 거북이를 응원하는 소리가 들립니다.'
        , '사슴: 토끼 이겨라! 토끼 이겨라! 너구리: 거북이 이겨라! 거북아 달려!! 새: 거북아, 할 수 있어! 화이팅!'
        , '열심히 달리고 있던 토끼는 잠시 나무 밑에 쉬어 가기로 했습니다. 그러나 토끼는 잠이 들고 말았습니다.'
        , '그러는 사이, 거북이는 열심히 기어서 결국 토끼가 자고 있는 나무 밑을 지나치게 되었습니다.'
        , '토끼: 흥! 내가 1 등이야. 잠시 쉬었다가도 거북이는 따라오지 못할거야. 1 시갂만 낮잠 좀 자야겠다. 아~~~함..'
        , '거북이: 하하하하.. 토끼녀석, 나를 느림보 거북이라고 놀렸겠다. 내가 이제 1 등이란다 토끼야!'
        , '토끼: 아니!! 잠시 쉬어갂다는게 1 시갂이나 지나다니…. 어라? 거북이가 이겼잖아?'
        , '토끼가 낮잠을 자는 사이, 거북이는 도착선에 도착하고 말았습니다. 거북이는 쉬지 않고 열심히 경주를 마쳤습니다.'
        , '그리하여 토끼보다 빨리 도착할 수 있었습니다.'
        , '하지만 토끼는 열심히 달렸지만, 중갂에 꾀를 써 결국 거북이에게 지고 말았습니다.'
        , '거북이는 저 멀리 보이는 토끼를 보며 흐뭇해 했습니다.'
      ]
    },
    spanish: {
      description: 'Spanish (español)',
      text: [
        'Érase una vez que había una mamá cerda que tenía tres cerditos. Ella los amaba mucho, pero no había suficiente comida para alimentarlos, así que los cerditos tuvieron que ir a buscar su suerte.'
        , 'El primer cerdito decidió ir al sur. Encontró a un granjero en el camino que estaba llevando un atado de paja. El cerdito preguntó respetuosamente: "¿Podría por favor darme esa paja, para que yo pueda construir una casa?"'
        , 'Como el cerdito dijo "por favor", el granjero le dio la paja y el cerdito construyó una bella casa. La casa tenía paredes hechas de paja, un piso hecho de paja, y adentro... una cómoda cama hecha de paja.'
        , 'Después de construir la casa, el cerdito decidió tomar una siesta en su cama hecha de paja. De pronto, el gran lobo malo llegó y olió al cerdito dentro de la casa, y su boca comenzó a hacerse agua."¡Mmmmm... emparedados de tocino!"'
        , 'Así que el lobo tocó la puerta de la casa hecha de paja y dijo: "¡Cerdito! ¡Cerdito! ¡Abre la puerta!"'
        , 'Pero el cerdito vio las grandes patas del lobo a través de la cerradura. El cerdito respondió: "¡No! ¡No! ¡No! ¡Ni por todo el oro del mundo!"'
        , 'El lobo mostró sus dientes y dijo: "Entonces soplaré y soplaré y derrumbaré tu casa."'
        , 'Así que sopló y sopló, y derrumbó la casa, y el cerdito corrió de vuelta a la casa de su madre.'
        , 'El segundo cerdito decidió ir al norte. Encontró a un granjero en el camino que estaba llevando un atado de madera. El cerdito preguntó respetuosamente: "¿Discúlpeme, podría darme esa madera para construir una casa?"'
        , 'Como el cerdito dijo "discúlpeme", el granjero le dio la madera, y el cerdito construyó una bella casa con ella. La casa tenía paredes hechas de madera, un piso hecho de madera, y adentro... una fuerte mesa hecha de madera.'
        , 'Después de construir la casa, el cerdito empezó a decorar su mesa hecha de madera con flores. De pronto, el gran lobo malo llegó y olió al cerdo dentro de la casa, y su estómago empezó a retumbar. "¡Mmmmm... Puerco rostizado!"'
        , 'Así que el lobo tocó la puerta de la casa hecha de madera y dijo: "¡Cerdito! ¡Cerdito! ¡Abre la puerta!"'
        , 'Pero el cerdito vio la larga nariz del lobo a través de la cerradura. El cerdito respondió: "¡No! ¡No! ¡No! ¡Ni por todo el oro del mundo!"'
        , 'El lobo mostró sus dientes y dijo: "Entonces soplaré y soplaré y derrumbaré tu casa."'
        , 'Así que sopló y sopló y derrumbó la casa, y el cerdito corrió de vuelta a la casa de su madre... ¡Mamá cerda no estaba muy feliz!'
        , 'El tercer cerdito decidió ir al oeste. Encontró a un granjero en el camino que estaba llevando una carga de ladrillos. El cerdito preguntó respetuosamente: "¡Hola señor!, ¿podría darme algunos de esos ladrillos para construir una casa?"'
        , 'Como lo llamó "señor", el granjero le dio al cerdito algunos ladrillos, y el cerdito construyó una bella casa con ellos. La casa tenía paredes hechas de ladrillo, un piso hecho de ladrillo, y adentro... una gran chimenea hecha de ladrillo.'
        , 'Después de construir la casa, el cerdito empezó a cocinar una gran olla de sopa en su chimenea hecha de ladrillo. De pronto, el gran lobo malo llegó y olió al cerdo dentro de la casa, y lamió sus labios. "¡Mmmmm... chuletas de cerdo con salsa barbacoa y frijoles verdes!"'
        , 'Así que el lobo tocó la puerta de la casa hecha de ladrillo y dijo: "¡Cerdito! ¡Cerdito!¡ ¡Abre la puerta!"'
        , 'Pero el cerdito vio las grandes orejas del lobo a través de la cerradura. El cerdito respondió: "¡No! ¡No! ¡No! ¡Ni por todo el oro en el mundo!"'
        , 'El lobo mostró sus dientes y dijo: "Entonces soplaré y soplaré y derrumbaré tu casa."'
        , 'Así que sopló y sopló. Sopló y sopló. Y sopló, y sopló y sopló; pero no pudo derrumbar la casa. Al final el lobo estuvo tan cansado que no pudo soplar más.'
        , 'El cerdito rio mientras removía su gran olla de sopa.'
        , 'Pero el lobo tenía tanta hambre de chuletas de puerco... ¡Él no se rendiría! Así que el lobo escaló al techo. "¡Ahora atraparé a ese cerdo!"'
        , 'El lobo bajó por la gran chimenea hecha de ladrillo y....¡PUM!... aterrizó de trasero en la gran olla de sopa del cerdito... ¡que estaba muy caliente! El lobo aulló y saltó fuera de la olla, y entonces corrió fuera de la casa sobando su trasero quemado.'
        , 'El cerdito llamó a su madre y sus dos hermanos en su celular hecho de ladrillo, y los invitó a una deliciosa cena de sopa de trasero de lobo.'
        , 'La sopa de trasero de lobo estuvo tan deliciosa que pronto todos querían atrapar al lobo y hacerlo sentarse en su sopa. El pobre lobo tuvo que correr muy lejos a lo profundo del bosque oscuro donde pudo vivir en paz y tranquilidad.'
      ]
    },
    chinese: {
      description: 'Chinese (中文)',
      text: [
        '很久很久以前有个⼤⼤的森林⾥⾯住着许许多多的动物'
        , '在森林的最北⾯有个⼤⼤的房⼦'
        , '房⼦⾥住着熊爸爸熊妈妈和熊宝宝'
        , '他们家⼈过着快快乐乐的⽇⼦'
        , '有天熊宝宝在书上看到了个新词语幸福但是他不知道幸福是什么意思'
        , '于是他就去问熊妈妈妈妈妈妈这⾥写着幸福'
        , '但是什么是幸福啊熊妈妈笑了笑说孩⼦你到森林⾥去'
        , '找朋友们问问吧然后你就知道啦'
        , '于是熊宝宝就⾃⼰⾛到森林⾥找答案'
        , '路上他遇到了许多⼩动物但是他们都说不知道'
        , '⼩熊没有放弃他在森林⾥⾛了圈⼜圈'
        , '可是还是不知道什么是幸福'
        , '傍晚熊宝宝⼜累⼜饿于是他决定先回家吃饭'
        , '回到家还没有进⻔熊宝宝就闻到了饭菜的⾹味'
        , '他⾼兴极了快快地跑进了家⻔'
        , '进厨房他就看到桌⼦上放满了各种各样他爱吃的菜'
        , '天上⻜的地上跑的⽔⾥游的什么都有'
        , '熊宝宝⾮常⾼兴地吃了起来'
        , '他太饿了吃得⼜快⼜多不会⼉就把饭菜全吃完了'
        , '个菜都没有剩下吃饱了以后他摸着⾃⼰圆⿎⿎的肚⼦问妈妈'
        , '妈妈妈妈我现在觉得很⾼兴'
        , '但是我还是不知道什么是幸福你能告诉我吗'
        , '妈妈笑了笑说傻孩⼦你现在的感觉就是幸福'
        , '我懂了妈妈那你觉得最幸福的事情是什么'
        , '妈妈笑着摸了摸熊宝宝的头说'
        , '我最⼤的幸福就是你天不在家'
      ]
    },
    french: {
      description: 'French (français)',
      text: [
        'Dimanche, les parents de Manu lui ont acheté un imperméable rouge.'
        , '— Maman, est-ce que je peux le mettre maintenant ? demanda Manu.'
        , '— Non, mon chéri. Il va bientôt pleuvoir, mais pour le moment, il fait beau, répondit la maman de Manu.'
        , 'Lundi, le temps était clair et ensoleillé.'
        , '— Est-ce qu\'il va pleuvoir aujourd\'hui, Maman ? demanda Manu.'
        , '— Non, Manu, pas aujourd\'hui. Si tu mets ton imper, tu auras l\'air malin, répondit lamaman de Manu.'
        , 'Mardi, le ciel était bleu.'
        , '— Maman, QUAND est-ce que mon souhait se réalisera ? demanda Manu.'
        , '— Pas aujourd\'hui, mon chéri. Il n\'y a qu\'un seul nuage blanc dans le ciel, répondit la maman de Manu.'
        , 'Mercredi, il faisait chaud.'
        , '— Maman, POURQUOI est-ce qu\'il ne pleut pas ? demanda Manu.'
        , '— Mon chéri, je pense qu\'il va pleuvoir très bientôt. Peut-être même avant midi, répondit la maman de Manu.'
        , 'Jeudi, Manu partit faire un pique-nique.'
        , '— Maman, et S\'IL pleuvait ? Dois-je prendre mon imper avec moi ? demanda Manu.'
        , '— Non, mon chéri, il ne pleuvra pas aujourd\'hui. Les petits nuages blancs sont trop haut dans le ciel, répondit la maman de Manu.'
        , 'Vendredi, le temps était couvert.'
        , '— Maman, est-ce qu\'il va pleuvoir aujourd\'hui ? demanda Manu.'
        , '— C\'est possible, mon chéri. Il y a des nuages noirs et bas dans le ciel, répondit la maman de Manu.'
        , 'La journée de samedi commença dans un fracas.'
        , 'Baoumbadaboum !'
        , '— Maman, est-ce le bruit du tonnerre ? Va-t-il bientôt pleuvoir ? demanda Manu.'
        , 'Et il se mit enfin à pleuvoir !'
        , '— Oh, il pleut, il pleut ! chanta Manu en se ruant dehors.'
        , '— Mais enfin, Manu, s\'écria sa maman en accourant derrière lui, tu as oublié ton imper !'
      ]
    },
    hindi: {
      description: 'Hindi (हिन्दी)',
      text: [
        'यह शहर इंसान को १०*१० के कमरे मैं कैद कर के रख देता है'
        , 'अपनों को अपनों से अलग कर के रख देता है'
        , 'हर कोई आता है यहाँ, अपना घोसला बनाने'
        , 'उसे अपने ही सपनो की उड़ान में जकड के रख देता है'
        , 'यह शहर इंसान को १०*१० के कमरे मैं कैद कर के रख देता है'
        , 'अपनों को अपनों से अलग कर के रख देता है'
        , 'वो  ठहाको   की  हंसी  को  एक  झूठी  मुस्कान  में  बदल  के  रख  देता  है'
        , 'रुपयों  के  लिये हर  किसी  का  ईमान  बदल  के  रख  देता  है'
        , 'जरूरत  पड़ने  पर  कोई  साथ  भी  नहीं  आता  साहब'
        , 'यह  शहर   दुनिया  से  इतना  अलग  कर  के रख  देता  है'
        , 'यह शहर इंसान को १०*१० के कमरे मैं कैद कर के रख देता है'
        , 'अपनों को अपनों से अलग कर के  रख देता है'
        , 'सुबह की चिडियो की चहचहाट को अलार्म क्लॉक में बदल कर रख देता है'
        , 'वो गाँव के मेलो और झूलो को , ट्रेड फेयर मैं तब्दील कर के रख देता है'
        , 'वो गाँव  की  चौपाल  को  सिनेमा  हॉल में कैद  कर के  रख  देता  है'
        , 'हद तो तब हो जाती है इस बदलाव की'
        , 'जब दादा दादी से लगाव को मम्मी पापा तक सीमित कर के रख देता है'
        , 'यह शहर इंसान को १०*१० के कमरे मैं कैद कर के रख देता है'
        , 'अपनों को अपनों से अलग कर के  रख देता है'
        , 'इन जगमगाती हुई सड़को पर चाँद की रोशनी कही खो जाती है'
        , 'वो  पीपल की छाँव के आगे AC की हवा भी मात खाती है'
        , 'फिर भी यह शहर इंसान को अपना बना के रख लेता है'
        , 'यह शहर इंसान को १०*१० के कमरे मैं कैद कर के रख देता है'
        , 'अपनों को अपनों से अलग कर के  रख देता है'
      ]
    }
  };

}

SampleText.prototype.getRandomSentence = function(language) {
  let arr = this.allLanguages[language].text;

  return arr[Math.floor(Math.random() * arr.length)];
}


var sampleText = new SampleText();

export default sampleText;

