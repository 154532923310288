
import cookie from './cookie';
import config from './clientServerConfig.json';
import dayjs from 'dayjs';
import sampleText from './sampleText';
import names from './names';

let STATE = {
  play: 'play',
  practiceFinished: 'practiceFinished',
  friendWait: 'friendWait',
  friendFinished: 'friendFinished',
  raceWait: 'raceWait',
  raceFinished: 'raceFinished',
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function AppData() {
}

AppData.prototype.init = function(options) {
  var self = this;

  options = options ? options : {};
  options.watch = options.watch ? options.watch : {};
  options.computed = options.computed ? options.computed : {};
  options.methods = options.methods ? options.methods : {};
  options.mounted = options.mounted ? options.mounted : function() {};

  var vue = {
    el: '#app',
    data: {
      dialog: {
        info: {
          button2: null,
          message: "",
          show: false,
          title: ""
        },
        join: {
          show: false,
        },
      },
      editName: false,
      elapsed: {
        start: 0,
        value: 0,
        text: '00:00',
        limit: 0
      },
      isCPM: false,
      isGameOwner: false,
      joinCode: '',
      pageloaderVisible: false,
      pageloaderText: 'Loading',
      playState: STATE.practicePlay,
      playMode: 'practice',
      timerVals: [
        {
          code: 's5',
          description: "5 second timer"
        },
        {
          code: '1',
          description: "1 minute timer"
        },
        {
          code: '2',
          description: "2 minute timer"
        },
        {
          code: '3',
          description: "3 minute timer"
        },
        {
          code: '5',
          description: "5 minute timer"
        },
        {
          code: '10',
          description: "10 minute timer"
        },
        {
          code: 'notimer',
          description: "No time limit"
        },
      ],
      totalCharacterCount: 0,
      typingLanguages: Object.keys(sampleText.allLanguages).map(x => {
        return {
          code: x,
          description: sampleText.allLanguages[x].description
        };
      }),
      typingInput: '',
      words: [],
      wpm: 0
    },
    watch: options.watch,
    computed: options.computed,
    methods: options.methods,
    mounted: options.mounted
  };

  vue.data.saved = {
    name: names.getName(),
    timerVal: {
      code: 'notimer',
      description: "No time limit"
    },
    typingLanguage: {
      code: 'english',
      description: 'English'
    },
    uuid: uuidv4(),
    wpmLevel: 10,
    wpmHistory: []
  };
  vue.data.save = function() {
    window.localStorage.setItem('savedVueData', JSON.stringify(self.vm.saved));
  };

  var savedVueData = window.localStorage.getItem('savedVueData');

  if (savedVueData) {
    vue.data.saved = $.extend(true, {}, vue.data.saved, JSON.parse(savedVueData));
  }

  this.vm = new Vue(vue);
};

var appData = new AppData();

export { STATE };

export default appData;
