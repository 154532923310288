import { uniqueNamesGenerator, Config, animals } from 'unique-names-generator';

let speedy = [
  , 'Apache'
  , 'Blustery'
  , 'Bullet'
  , 'Buzz'
  , 'Comet'
  , 'Cougar'
  , 'Falcon'
  , 'Faster'
  , 'Flash'
  , 'Ghost'
  , 'Harley'
  , 'Jet'
  , 'Jump'
  , 'Jumping'
  , 'Lightning'
  , 'Miles'
  , 'Mustang'
  , 'Pony Express'
  , 'Racer'
  , 'Rapid'
  , 'Rocket'
  , 'Sonic'
  , 'Speedy'
  , 'Speedster'
  , 'Taz'
  , 'Tornado'
  , 'Traveler'
  , 'Velocity'
  , 'Voyager'
  , 'Wild'
  , 'Wildfire'
];

const configUniqueNamesGeneratorAnimals = {
  dictionaries: [animals],
  style: 'capital'
}
const configUniqueNamesGeneratorSpeedy = {
  dictionaries: [speedy],
  style: 'capital'
}

function Names() {
}

// Function to generate random number  
function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min +1)) + min;
}  

Names.prototype.getName = function() {
  return uniqueNamesGenerator(randomNumber(0, 1000) < 500 ? configUniqueNamesGeneratorSpeedy : configUniqueNamesGeneratorAnimals);
}

var names = new Names();

export default names;
